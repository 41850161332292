/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
    // {
    //     url: "/dashboard",
    //     name: "Dashboard",
    //     slug: "dashboard",
    //     icon: "HomeIcon",
    //     i18n: "Dashboard"
    // },
    {
        header: "Super Admin",
        icon: "PackageIcon",
        i18n: "Super Admin",
        rule: 'admin',
        items: [
            {
                url: "/admin/manage-organization",
                name: "navMenu.organization",
                slug: "manage-organization",
                icon: "GlobeIcon",
                i18n: "navMenu.organization",
                rule: 'admin',
            },
            {
                url: null,
                name: 'Camera',
                i18n: 'Manage',
                icon: 'CameraIcon',
                rule: 'admin',
                submenu: [
                    {
                        url: "/admin/manage-camera",
                        name: "Camera",
                        slug: "admin-manage-camera",
                        icon: "SidebarIcon",
                        i18n: "Camera",
                    },
                    {
                        url: "/admin/camera-monitor",
                        name: "navMenu.cameraMonitor",
                        slug: "admin-camera-monitor",
                        // icon: "SettingsIcon",
                        i18n: "navMenu.cameraMonitor",
                    },
                    {
                        url: "/admin/camera-serviceAI",
                        name: "navMenu.cameraServiceAI",
                        slug: "admin-camera-serviceAI",
                        // icon: "SettingsIcon",
                        i18n: "navMenu.cameraServiceAI",
                    },
                    {
                        url: "/admin/camera-history",
                        name: "navMenu.cameraHistory",
                        slug: "admin-history-camera",
                        icon: "SettingsIcon",
                        i18n: "navMenu.cameraHistory",
                    },
                ]
            },
            {
                url: null,
                name: 'navMenu.hardwareDeviceManage',
                i18n: 'navMenu.hardwareDeviceManage',
                icon: 'CpuIcon',
                rule: 'admin',
                submenu: [
                    {
                        url: '/admin/manage-hardware-devices',
                        name: 'navMenu.listHardwareDevice',
                        slug: 'manage-hardware-device',
                        icon: 'CpuIcon',
                        i18n: 'navMenu.listHardwareDevice'
                    },
                ]
            },
            {
                url: null,
                name: 'navMenu.servicePack',
                i18n: 'Manage',
                icon: 'PackageIcon',
                rule: 'admin',
                submenu: [
                    {
                        url: "/admin/permission-function",
                        name: "navMenu.funcAuthority",
                        slug: "permission-function",
                        icon: "SidebarIcon",
                        i18n: "navMenu.funcAuthority",
                    },
                    {
                        url: "/admin/organization-package",
                        name: "navMenu.servicePack",
                        slug: "organization-package",
                        icon: "PackageIcon",
                        i18n: "navMenu.servicePack",
                    },
                    {
                        url: "/admin/history-register-package",
                        name: "navMenu.requestHistory",
                        slug: "history-register-package",
                        icon: "PackageIcon",
                        i18n: "navMenu.requestHistory",
                    },
                ]
            },
            {
                url: "/admin/minio-storage",
                name: "navMenu.storageCamera",
                slug: "admin-manage-ftp",
                icon: "DatabaseIcon",
                i18n: "navMenu.storageCamera",
                rule: 'admin',
            },
            {
                url: null,
                name: 'navMenu.manageOrgan',
                i18n: 'navMenu.manageOrgan',
                icon: 'UsersIcon',
                rule: 'organization_organizationBranch',
                submenu: [
                    {
                        url: "/user/organization-info",
                        name: "navMenu.organizationInformation",
                        slug: "user-organization-info",
                        icon: "GlobeIcon",
                        i18n: "navMenu.organizationInformation",
                    },
                    {
                        url: "/user/organization-branch",
                        name: "navMenu.branch",
                        slug: "user-organization-branch",
                        icon: "GitBranchIcon",
                        i18n: "navMenu.branch",
                    },
                    // {
                    //     url: "/user/manage-employee",
                    //     name: "navMenu.personnel",
                    //     slug: "user-manage-employee",
                    //     icon: "UsersIcon",
                    //     i18n: "navMenu.personnel",
                    // },
                ]
            },
            // {
            //     url: null,
            //     name: 'navMenu.customerManagement',
            //     i18n: 'navMenu.customerManagement',
            //     icon: 'UserPlusIcon',
            //     rule: 'organization',
            //     submenu: [
            //         {
            //             url: "/user/customer",
            //             name: "navMenu.customer",
            //             slug: "user-customer",
            //             icon: "UserMinusIcon",
            //             i18n: "navMenu.customer",
            //         },
            //         {
            //             url: "/user/in-out-history-customer",
            //             name: "navMenu.historyInOut",
            //             slug: "user-in-out-history-customer",
            //             icon: "SidebarIcon",
            //             i18n: "navMenu.historyInOut",
            //         },
            //         {
            //             url: "/user/in-out-history-customer-realtime",
            //             name: "navMenu.customerInOut",
            //             slug: "user-in-out-history-customer-realtime",
            //             icon: "SidebarIcon",
            //             i18n: "navMenu.customerInOut",
            //         }
            //     ]
            // },
            {
                url: null,
                name: 'navMenu.cameraManage',
                i18n: 'navMenu.cameraManage',
                icon: 'VideoIcon',
                rule: 'organization_organizationBranch',
                submenu: [
                    {
                        url: "/user/stream-camera",
                        name: "navMenu.stream",
                        slug: "stream-camera",
                        icon: "EyeIcon",
                        i18n: "navMenu.stream",
                    },
                    {
                        url: "/user/play-back",
                        name: "navMenu.playBack",
                        slug: "license",
                        icon: "FilmIcon",
                        i18n: "navMenu.playBack",
                    },
                    // {
                    //     url: "/user/manage-camera",
                    //     name: "Camera",
                    //     slug: "user-manage-camera",
                    //     icon: "CameraIcon",
                    //     i18n: "License",
                    // },
                    {
                        url: "/user/manage-camera-layout",
                        name: "navMenu.listCameras",
                        slug: "user-manage-camera-layout",
                        icon: "VideoIcon",
                        i18n: "navMenu.listCameras",
                    },
                    {
                        url: "/user/manage-camera-group",
                        name: "navMenu.groupCamera",
                        slug: "user-manage-camera-group",
                        icon: "SettingsIcon",
                        i18n: "navMenu.groupCamera",
                    },
                    {
                        url: "/user/camera-history",
                        name: "navMenu.cameraHistory",
                        slug: "camera-history",
                        icon: "SettingsIcon",
                        i18n: "navMenu.cameraHistory",
                    },
                    // {
                    //     url: "/user/minio-storage",
                    //     name: "navMenu.storageCamera",
                    //     slug: "minio-storage",
                    //     icon: "SettingsIcon",
                    //     i18n: "navMenu.storageCamera",
                    // },

                ]
            },
            // {
            //     url: null,
            //     name: 'navMenu.hardwareDeviceManage',
            //     i18n: 'navMenu.hardwareDeviceManage',
            //     icon: 'CpuIcon',
            //     rule: 'organization_organizationBranch',
            //     submenu: [
            //         {
            //             url: '/user/manage-hardware-devices',
            //             name: 'navMenu.listHardwareDevice',
            //             slug: 'manage-hardware-device',
            //             icon: 'CpuIcon',
            //             i18n: 'navMenu.listHardwareDevice'
            //         },
                    // {
                    //     url: '/user/hardware-in-out-history',
                    //     name: 'navMenu.hardwareInOut',
                    //     slug: 'hardware-in-out',
                    //     icon: 'CpuIcon',
                    //     i18n: 'navMenu.hardwareInOut'
                    // },
            //     ]
            // },
            // {
            //     url: null,
            //     name: 'navMenu.intelligentHandling',
            //     i18n: 'navMenu.intelligentHandling',
            //     icon: 'EyeIcon',
            //     rule: 'organization_organizationBranch',
            //     submenu: [

            //         {
            //             url: "/user/heat-map",
            //             name: "navMenu.heatMap",
            //             slug: "heat-map",
            //             icon: "SmileIcon",
            //             i18n: "License",
            //         },
                    // {
                    //     url: "/user/fire-alarm",
                    //     name: "navMenu.fireAlarm",
                    //     slug: "fire-alarm",
                    //     icon: "SmileIcon",
                    //     i18n: "License",
                    // },
            //         {
            //             url: "/user/face-recognition",
            //             name: "navMenu.faceRecognitionSetting",
            //             slug: "face-recognition",
            //             icon: "ListIcon",
            //             i18n: "navMenu.faceRecognition",
            //             submenu: [
            //                 {
            //                     url: "/user/face-recognition",
            //                     name: "navMenu.faceRecognition",
            //                     slug: "face-recognition",
            //                     icon: "SmileIcon",
            //                     i18n: "navMenu.faceRecognition",
            //                 },
            //                 {
            //                     url: "/user/in-out-history",
            //                     name: "navMenu.historyInOut",
            //                     slug: "personnel",
            //                     icon: "SidebarIcon",
            //                     i18n: "navMenu.historyInOut",
            //                 },
            //             ]
            //         },

            //         {
            //             url: "/user/ai-restrictedarea",
            //             name: "navMenu.intrusionDetection",
            //             slug: "ai-restrictedarea",
            //             icon: "ListIcon",
            //             i18n: "navMenu.intrusionDetection",
            //             submenu: [
            //                 {
            //                     url: "/user/ai-restrictedarea",
            //                     name: "navMenu.intrusiveSettings",
            //                     slug: "ai-restrictedarea",
            //                     i18n: "navMenu.intrusiveSettings",
            //                 },
            //                 {
            //                     url: "/user/ai-restrictedarea-history",
            //                     name: "navMenu.infiltrationHistory",
            //                     slug: "ai-restrictedarea-history",
            //                     i18n: "navMenu.infiltrationHistory",
            //                 },
            //             ]
            //         },
            //     ]
            // },
            {
                url: "/user/manage-event",
                name: "navMenu.event",
                slug: "user-manage-event",
                icon: "BellIcon",
                i18n: "navMenu.event",
                rule: 'organization_organizationBranch'
            },
            {
                url: "/user/transaction-history",
                name: "navMenu.transactionHistory",
                slug: "transaction-history",
                icon: "PackageIcon",
                i18n: "navMenu.transactionHistory",
                rule: "organization"
            },
            // {
            //     url: null,
            //     name: "navMenu.externalDevice",
            //     slug: "card-camera",
            //     icon: "RadioIcon",
            //     i18n: "navMenu.intrusionDetection",
            //     rule: "organization",
            //     submenu: [
            //         {
            //             url: "/user/card-customer",
            //             name: "cardCustomer.magneticCard",
            //             slug: "card-camera",
            //             i18n: "navMenu.cardCustomer",
            //         }
            //     ]
            // },
        ]
    },
]
