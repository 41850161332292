<!-- =========================================================================================
  File Name: VerticalNavMenu.vue
  Description: Vertical NavMenu Component
  Component Name: VerticalNavMenu
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="parentx">

    <vs-sidebar
      class="v-nav-menu items-no-padding"
      v-model="isVerticalNavMenuActive"
      ref="verticalNavMenu"
      default-index="-1"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      v-hammer:swipe.left="onSwipeLeft">

      <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">

        <!-- Header -->
        <div class="header-sidebar flex items-end justify-between" slot="header">

          <!-- Logo -->
          <div class="logo">
            <router-link tag="div" class="vx-logo cursor-pointer flex items-center" to="/">
              <!--            <logo class="w-10 mr-4 fill-current text-primary" />-->
              <img src="../../../assets/images/logo/logov2cam.png" height="50" class="ml-1"/>
              <span class="vx-logo-text text-primary ml-5" v-show="isMouseEnter || !reduce" v-if="title">{{
                  title
                }}</span>
            </router-link>
          </div>

          <!-- /Logo -->

          <!-- Menu Buttons -->
          <div>
            <!-- Close Button -->
            <template v-if="showCloseButton">
              <feather-icon icon="XIcon" class="m-0 cursor-pointer"
                            @click="$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)"/>
            </template>

            <!-- Toggle Buttons -->
            <template v-else-if="!showCloseButton && !verticalNavMenuItemsMin">
              <feather-icon
                id="btnVNavMenuMinToggler"
                class="mr-0 cursor-pointer"
                :icon="reduce ? 'MenuIcon' : 'MenuIcon'"
                svg-classes="stroke-current"
                @click="toggleReduce(!reduce)"/>
            </template>
          </div>
          <!-- /Menu Toggle Buttons -->
        </div>
        <!-- /Header -->

        <!-- Header Shadow -->
<!--        <div class="shadow-bottom" v-show="showShadowBottom"/>-->

        <!-- Menu Items -->
        <VuePerfectScrollbar ref="verticalNavMenuPs" class="scroll-area-v-nav-menu pt-2" :settings="settings"
                             @ps-scroll-y="psSectionScroll" :key="$vs.rtl">
          <template v-for="(item, index) in menuItemsUpdated">

            <!-- Group Header -->
            <span v-if="item.header && !verticalNavMenuItemsMin && roleItem(item.rule)"
                  class="navigation-header truncate" :key="`header-${index}`">
              {{ item.name }}
            </span>
            <!-- /Group Header -->

            <template v-else-if="!item.header && roleItem(item.rule)" class="text-menu">

              <!-- Nav-Item -->
              <v-nav-menu-item
                v-if="!item.submenu"
                :key="`item-${index}`"
                :index="index"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon" :target="item.target"
                :isDisabled="item.isDisabled"
                :slug="item.slug">
                <span v-show="!verticalNavMenuItemsMin" class="truncate">{{$t(item.name) }}</span>
                <vs-chip class="ml-auto" :color="item.tagColor" v-if="item.tag && (isMouseEnter || !reduce)">{{
                    item.tag
                  }}
                </vs-chip>
              </v-nav-menu-item>

              <!-- Nav-Group -->
              <template v-else>
                <v-nav-menu-group
                  :key="`group-${index}`"
                  :openHover="openGroupHover"
                  :group="item"
                  :groupIndex="index"
                  :open="isGroupActive(item)"/>
              </template>
              <!-- /Nav-Group -->



            </template>
          </template>
<!--            <div v-if="!verticalNavMenuItemsMin">-->
<!--                <div class="download-box-container-light">-->
<!--                    <div class="label-container-light"><p class="label-light downloadapp">-->
<!--                        {{ $t('download.textDownload') }}</p></div>-->
<!--                    <div class="img-container-light img-hover-jump">-->
<!--                        <div class="w-1/8">-->
<!--                            <a href="https://play.google.com/store/apps/details?id=com.intlabs.intcam" target="_blank">-->
<!--                                <img src="../../../assets/images/pages/google-play_for-light.png"/></a>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; /Header &ndash;&gt;-->

<!--                        &lt;!&ndash; Header Shadow &ndash;&gt;-->
<!--                        &lt;!&ndash;        <div class="shadow-bottom" v-show="showShadowBottom"/>&ndash;&gt;-->
<!--                        <div class="vertical-line"></div>-->
<!--                        <div class="w-1/6 zoom-qr">-->
<!--                  <span>-->
<!--                      <img src="../../../assets/images/pages/androi_qr.png" height="150" width="150"/>-->
<!--                    <br/>-->
<!--                   <h5 class="ml-5 mb-2" style="color: #8e9196; font-size: 10px;">{{ $t('download.scan') }}</h5>-->

<!--                  </span>-->
<!--                            <img src="../../../assets/images/pages/androi_qr.png" height="33" width="30"/>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="img-container-light popup img-hover-jump">-->
<!--                        <div class="w-1/8">-->
<!--                            <a href="https://apps.apple.com/vn/app/intface-cloud/id1599027770" target="_blank">-->
<!--                                <img src="../../../assets/images/pages/app-store_for-light.png"/></a>-->
<!--                        </div>-->

<!--                        <div class="vertical-line"></div>-->

<!--                        <div class="w-1/6 zoom-qr">-->
<!--                  <span>-->
<!--                      <img src="../../../assets/images/pages/ios_qr.png" height="150" width="150"/>-->

<!--                        <h5 class="ml-5 mb-2" style="color: #8e9196; font-size: 10px;">{{ $t('download.scan') }}</h5>-->

<!--                  </span>-->
<!--                            <img src="../../../assets/images/pages/ios_qr.png" height="33" width="30"/>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
        </VuePerfectScrollbar>

          <!-- /Menu Items -->
          <!-- /Menu Toggle Buttons -->
          <div class="footer-sidebar items-end">

              <p class="label-light verstyle mt-2">Version 1.0.0</p>

          </div>

      </div>

        </vs-sidebar>

        <!-- Swipe Gesture -->
        <div
            v-if="!isVerticalNavMenuActive"
            class="v-nav-menu-swipe-area"
            v-hammer:swipe.right="onSwipeAreaSwipeRight"/>
        <!-- /Swipe Gesture -->

    </div>


</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VNavMenuGroup from './VerticalNavMenuGroup.vue'
import VNavMenuItem from './VerticalNavMenuItem.vue'

import Logo from "../Logo.vue"

export default {
    name: 'v-nav-menu',
    components: {
        VNavMenuGroup,
        VNavMenuItem,
        VuePerfectScrollbar,
        Logo
    },
    props: {
        logo: {type: String},
        openGroupHover: {type: Boolean, default: false},
        parent: {type: String},
        reduceNotRebound: {type: Boolean, default: true},
        navMenuItems: {type: Array, required: true},
        title: {type: String},
    },
    data: () => ({
        clickNotClose: false, // disable close navMenu on outside click
        isMouseEnter: false,
        reduce: false, // determines if navMenu is reduce - component property
        showCloseButton: false, // show close button in smaller devices
        settings: {      // perfectScrollbar settings
            maxScrollbarLength: 60,
            wheelSpeed: 1,
            swipeEasing: true
        },
        showShadowBottom: false,
    }),
    computed: {
        checkMenuGroup() {
            return (item) => {
                console.log("item_", item);
                console.log("this.$route.meta.rule", this.$route.meta.rule);
                if (item.rule == this.$route.meta.rule) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isGroupActive() {
            return (item) => {
                const path = this.$route.fullPath
                const routeParent = this.$route.meta ? this.$route.meta.parent : undefined
                let open = false

                let func = (item) => {
                    if (item.submenu) {
                        item.submenu.forEach((item) => {
                            if (item.url && (path === item.url || routeParent === item.slug)) {
                                open = true
                            } else if (item.submenu) {
                                func(item)
                            }
                        })
                    }
                }
                func(item)
                return open
            }
        },
        menuItemsUpdated() {
            let clone = this.navMenuItems.slice()

            for (let [index, item] of this.navMenuItems.entries()) {
                if (item.header && item.items.length && (index || 1)) {
                    let i = clone.findIndex(ix => ix.header === item.header)
                    for (let [subIndex, subItem] of item.items.entries()) {
                        clone.splice(i + 1 + subIndex, 0, subItem)
                    }
                }
            }
            return clone
        },
        isVerticalNavMenuActive: {
            get() {
                return this.$store.state.isVerticalNavMenuActive
            },
            set(val) {
                this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val)
            }
        },
        layoutType() {
            return this.$store.state.mainLayoutType
        },
        reduceButton: {
            get() {
                return this.$store.state.reduceButton
            },
            set(val) {
                this.$store.commit('TOGGLE_REDUCE_BUTTON', val)
            }
        },
        isVerticalNavMenuReduced() {
            return Boolean(this.reduce && this.reduceButton)
        },
        verticalNavMenuItemsMin() {
            return this.$store.state.verticalNavMenuItemsMin
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },

    },
    watch: {
        '$route'() {
            if (this.isVerticalNavMenuActive && this.showCloseButton) this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        },
        reduce(val) {
            const verticalNavMenuWidth = val ? "reduced" : this.$store.state.windowWidth < 1200 ? "no-nav-menu" : "default"
            this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

            setTimeout(function () {
                window.dispatchEvent(new Event('resize'))
            }, 100)
        },
        layoutType() {
            this.setVerticalNavMenuWidth()
        },
        reduceButton() {
            this.setVerticalNavMenuWidth()
        },
        windowWidth() {
            this.setVerticalNavMenuWidth()
        }
    },
    methods: {
        roleItem(rule) {
            let isMatch = false;
            if (rule === 'organization_organizationBranch') {
                let rules = 'organization_organizationBranch'.split("_");
                if (rules.find(x => x === this.$acl.get[0])) isMatch = true;
            } else {
                if (rule === this.$acl.get[0]) isMatch = true;
            }
            return isMatch;
        },
        // handleWindowResize(event) {
        //   this.windowWidth = event.currentTarget.innerWidth;
        //   this.setVerticalNavMenuWidth()
        // },
        onSwipeLeft() {
            if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
        },
        onSwipeAreaSwipeRight() {
            if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
        },
        psSectionScroll() {
            this.showShadowBottom = this.$refs.verticalNavMenuPs.$el.scrollTop > 0 ? true : false
        },
        mouseEnter() {
            if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)
            this.isMouseEnter = true
        },
        mouseLeave() {
            if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', true)
            this.isMouseEnter = false;
        },
        setVerticalNavMenuWidth() {

            if (this.windowWidth > 1200) {
                if (this.layoutType === 'vertical') {

                    // Set reduce
                    this.reduce = this.reduceButton ? true : false

                    // Open NavMenu
                    this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

                    // Set Menu Items Only Icon Mode
                    const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false
                    this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

                    // Menu Action buttons
                    this.clickNotClose = true
                    this.showCloseButton = false

                    const verticalNavMenuWidth = this.isVerticalNavMenuReduced ? "reduced" : "default"
                    this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

                    return
                }
            }

            // Close NavMenu
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

            // Reduce button
            if (this.reduceButton) this.reduce = false

            // Menu Action buttons
            this.showCloseButton = true
            this.clickNotClose = false

            // Update NavMenu Width
            this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

            // Remove Only Icon in Menu
            this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)


            // if(this.layoutType === 'vertical' || (this.layoutType === 'horizontal' && this.windowWidth < 1200))
            // if (this.windowWidth < 1200) {

            //   // Close NavMenu
            //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

            //   // Reduce button
            //   if (this.reduceButton) this.reduce = false

            //   // Menu Action buttons
            //   this.showCloseButton = true
            //   this.clickNotClose   = false

            //   // Update NavMenu Width
            //   this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

            //   // Remove Only Icon in Menu
            //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)

            // } else {

            //   // Set reduce
            //   this.reduce = this.reduceButton ? true : false

            //   // Open NavMenu
            //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

            //   // Set Menu Items Only Icon Mode
            //   const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false
            //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

            //   // Menu Action buttons
            //   this.clickNotClose   = true
            //   this.showCloseButton = false

            //   const verticalNavMenuWidth   = this.isVerticalNavMenuReduced ? "reduced" : "default"
            //   this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)
            // }
        },
        toggleReduce(val) {
            this.reduceButton = val
            this.setVerticalNavMenuWidth()
        },
    },
    mounted() {
        this.setVerticalNavMenuWidth();
        this.$eventBus.$on(this.nameEventBusUse, (data) => {
            this.weekUse = data.data;
        });
    },
}

</script>

<style scoped>
.vx-logo-text {
    color: white !important;
}

.download-box-container-light {
    /*background-color: #E5E5E5;*/
    border: 1px solid #f3f3f3;
    margin: 10px;
    border-radius: 6px;
    flex-direction: column;
    display: flex;
    padding: 20px;
}

.label-container-light {
    align-self: center;
    margin-bottom: 10px;
}

.label-light {
    font-size: 15px;
    font-weight: 600;
    color: rgba(var(--vs-primary),1);
    margin-bottom: 0;
    text-align: center
}

.verstyle {

    font-size: 10px;
    color: rgba(var(--vs-primary),1);
}

.downloadapp {
    text-transform: uppercase;
}

.v-nav-menu .scroll-area-v-nav-menu {
    position: relative;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 90px);
}

.img-container-light {
    border: 2px solid #ccc;
    border-radius: 20px;
    flex: 1 1;
    display: flex;
    justify-content: center !important;
    margin-top: 10px;
    margin-left: 0;
    align-items: center;
    cursor: pointer;
    width: 200px;
    position: relative;
}

.img-container-light img {
    padding-top: 5px;

}

.img-btn {
    justify-content: center !important;
}

.truncate {
    font-size: 14px;
}

.footer {
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    background-color: white !important;
    text-align: center !important;
    width: 13% !important;
}

.vertical-line {
    border: 1px solid #ccc;
    height: 25px;
    margin-left: 30px;
    margin-right: 10px;
}

div.zoom-qr span {
    border-radius: 3px;
    box-shadow: 0px 0px 8px 4px rgba(var(--vs-primary),1);
    /*opacity: 0.8;*/
    display: none;
    margin-left: 111px;
    padding-right: 100px;
    width: 169px;
    transition: transform .2s;
    margin-top: -180px;
    background-color: white;
}

div.zoom-qr:hover span {
    display: inline;
    position: fixed;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;

}

.img-hover-jump:hover {
    animation: hover-jump .3s ease-in-out;
}

@keyframes hover-jump {
    from {
        top: 0px;
    }
    to {
        bottom: 2px;
    }
}

.v-nav-menu .header-sidebar .feather-icon svg {
    color: rgba(var(--vs-primary),1) !important;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/components/verticalNavMenu.scss"
</style>

