<template>
    <div class="the-navbar__user-meta flex items-center" v-if="userInfo.fullName">

        <div  class="text-right leading-tight hidden sm:block">
            <p v-if="ischec" class="font-semibold">{{ userInfo.fullName }}</p>
            <small class="font-semibold">{{ userInfo.username }}</small>
        </div>

        <vs-dropdown class="cursor-pointer"  vs-trigger-click @mousemove="ischecs" @mouseleave="ischeco">

            <div class="con-img ml-3">
                <img v-if="!image" key="onlineImg" :src="userInfo.photoURL" alt="user-img" width="40"
                     height="40" class="rounded-full shadow-md cursor-pointer block"/>
                <img v-else key="onlineImg" :src="image" alt="user-img" width="40"
                     height="40" class="rounded-full shadow-md cursor-pointer block"/>
            </div>

            <vs-dropdown-menu class="vx-navbar-dropdown">
                <ul style="min-width: 12rem">

                    <li v-if="ShowHiddenProfileOption" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="$router.push('/user/organization-info').catch(() => {})">
                        <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
                        <span class="ml-2">{{$t('profile.manageOrgan')}}</span>
                    </li>

                    <vs-divider v-if="ShowHiddenProfileOption" class="m-1"/>

                    <li
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="handlerRule">
                        <feather-icon icon="LockIcon" svgClasses="w-4 h-4"/>
                        <span class="ml-2">{{$t('profile.resetPass')}}</span>
                    </li>

                    <vs-divider class="m-1"/>

                    <li
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="handlerRuleKey" v-if="false">
                        <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4"/>
                        <span class="ml-2">{{$t('profile.getKeyApi')}}</span>
                    </li>

<!--                    <vs-divider class="m-1"  v-if="userInfo.userRole == 'admin'"/>-->

                    <li
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="logout">
                        <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
                        <span class="ml-2">{{$t('profile.logout')}}</span>
                    </li>
                </ul>
            </vs-dropdown-menu>
        </vs-dropdown>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInfo: {},
            ShowHiddenProfileOption: true,
            image: null,
            ischec: false,
        }
    },
    watch: {
        '$store.state.userInformation'(val) {
            if (!this.userInfo.id) {
                this.userInfo = Object.assign({}, this.$store.state.AppActiveUser, val);
            }
        }
    },
    created() {
        this.initScreen();
    },
    methods: {
        ischecs(){
            this.ischec = true
        },
        ischeco(){
            this.ischec = false
        },
        getInfo: function(){
            this.$oauth.get('/user/current').then((res) => {
                if (res.data.img.includes('infocare')) {
                    this.image = res.data.img.replace('cdn.infocare', 'cdnimage.inviv')
                } else {
                    this.image = res.data.img;
                }
            }).catch(() => {})
        },
        ShowHiddenProfile(){
            if (this.$router.currentRoute.meta.rule == 'admin') {
              this.ShowHiddenProfileOption = false
            }
        },
        handlerRule(){

            if (this.$router.currentRoute.meta.rule == 'admin') {
                this.$router.push(`/admin/reset-password`).catch(() => {
                })
            }
            if (this.$router.currentRoute.meta.rule == 'organization' || this.$router.currentRoute.meta.rule == 'organization_organizationBranch') {
                this.$router.push(`/user/reset-password`).catch(() => {
                })
            }
        },
        handlerRuleKey(){
            if (this.$router.currentRoute.meta.rule == 'admin') {
                this.$router.push(`/admin/get-key`).catch(() => {
                })
            }
            if (this.$router.currentRoute.meta.rule == 'organization') {
                this.$router.push(`/user/get-key`).catch(() => {
                })
            }
        },
        logout() {
            if (localStorage.getItem("deviceToken")) {
                this.$crm.post('/notification/unsubscribe', {tokens: [localStorage.getItem("deviceToken")]}).then((response) => {
                    this.$vs.loading.close();
                    this.$router.push('/pages/login')
                }).catch(() => {
                    this.$vs.loading.close();
                }).finally(() => {
                    this.$router.push('/pages/login')
                    localStorage.removeItem("access_token");
                    localStorage.clear();
                });
            } else {
                localStorage.removeItem("access_token");
                location.reload();
            }
        },
        initScreen(){
            this.userInfo = Object.assign({}, this.$store.state.AppActiveUser, JSON.parse(localStorage.getItem('userInfo')));
            this.ShowHiddenProfile();
            this.getInfo();
        }
    },
    mounted(){
        this.$eventBus.$on('update-avatar',($event) => {
            if  ($event) this.initScreen();
        })
    }
}
</script>

<style scoped>
.text-right {
    color: white;
}
</style>

