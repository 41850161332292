<template>
    <div>

        <vs-popup class="getHelp" :title="$t('getHelpAskedQuestions.title')" :active.camel="openPopup">

            <div class="iv-ui-popup-dialog__content">
                <div class="iv-ui-form-content">
                    <div class="iv-ui-form-content__top-content"></div>
                    <div class="iv-ui-form-main-content">
                        <div class="iv-help-content">
                            <div class="iv-help-content__preface"><span style="font-size: 25px; font-weight: 10px; color: black">{{$t('getHelpAskedQuestions.text2')}}</span></div>
                            <div class="iv-help-content__body">
                                <div class="iv-help-section">
                                    <div class="iv-help-section__title">{{$t('getHelpAskedQuestions.text3')}}</div>
                                    <div class="iv-help-section__content">
                                        <div class="iv-ui-accordion">
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop1"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text4')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop1" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text5')}}</p></span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text6')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop2"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text7')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon2"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop2" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p><p><b>{{$t('getHelpAskedQuestions.text8')}}</b></p>
{{$t('getHelpAskedQuestions.text9')}}

</span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p><p><b>{{$t('getHelpAskedQuestions.text10')}}</b></p>{{$t('getHelpAskedQuestions.text11')}}ộ


</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop3"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">

                                                        <span>{{$t('getHelpAskedQuestions.text12')}}</span></div>

                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon3"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop3" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text13')}}

</p></span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text14')}}

</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop4"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text15')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon4"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop4" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text16')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text17')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop5"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text18')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon5"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop5" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text19')}}

</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop6"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text20')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon6"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop6" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text21')}}


</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop7"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text22')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon7"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop7" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text23')}}


</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop8"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text24')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon8"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop8" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text25')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop9"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text26')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon9"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop9" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text27')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop10"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text28')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon10"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop10" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text29')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop11"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text30')}}
</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon11"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop11" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text31')}}
</p></span>
                                                                        <ul>
                                                                            <li>{{$t('getHelpAskedQuestions.text32')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text33')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text34')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text35')}}

                                                                            </li>
                                                                            <li>{{$t('getHelpAskedQuestions.text36')}}

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop12"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text37')}}
</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon12"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop12" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text38')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text39')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text40')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop13"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text41')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon13"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop13" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text42')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop14"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text43')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon14"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop14" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text44')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop15"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text45')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon15"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop15" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text46')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop16"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text47')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon16"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop16" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text48')}}
</p></span>
                                                                        <ul>
                                                                            <li>{{$t('getHelpAskedQuestions.text49')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text50')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text51')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text52')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text53')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text54')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text55')}}</li>
                                                                            <li>{{$t('getHelpAskedQuestions.text56')}}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop17"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text57')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon17"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop17" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text58')}}
</p></span>

                                                                    </div>
                                                                </div>
                                                                <div><img src="@/assets/images/portrait/goicuoc.png"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop18"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text59')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon18"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop18" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text60')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop19"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpAskedQuestions.text61')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon19"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop19" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text62')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-text"><span><p></p><p>{{$t('getHelpAskedQuestions.text63')}}
</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="iv-ui-form-content__footer-content"></div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
    components: {
        vSelect
    },
    data() {
        return {
            cdDrop1: false, cdDrop2: false,  cdDrop3: false, cdDrop4: false, cdDrop5: false,cdDrop6: false,  cdDrop7: false, cdDrop8: false, cdDrop9: false, cdDrop10: false, cdDrop11: false, cdDrop12: false, cdDrop13: false, cdDrop14: false, cdDrop15: false, cdDrop16: false, cdDrop17: false, cdDrop18: false, cdDrop19: false,
            rotateIcon: 'iv-ui-svg-icon',rotateIcon2: 'iv-ui-svg-icon',rotateIcon3: 'iv-ui-svg-icon',rotateIcon4: 'iv-ui-svg-icon',rotateIcon5: 'iv-ui-svg-icon',rotateIcon6: 'iv-ui-svg-icon', rotateIcon7: 'iv-ui-svg-icon',rotateIcon8: 'iv-ui-svg-icon',rotateIcon9: 'iv-ui-svg-icon',rotateIcon10: 'iv-ui-svg-icon',rotateIcon11: 'iv-ui-svg-icon',rotateIcon12: 'iv-ui-svg-icon',rotateIcon13: 'iv-ui-svg-icon',rotateIcon14: 'iv-ui-svg-icon',rotateIcon15: 'iv-ui-svg-icon',rotateIcon16: 'iv-ui-svg-icon',rotateIcon17: 'iv-ui-svg-icon',rotateIcon18: 'iv-ui-svg-icon',rotateIcon19: 'iv-ui-svg-icon',
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        closePopup: {
            type: Function
        },
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        cldDrop1(){
            this.cdDrop1 = !this.cdDrop1;
            let ccRotate = this.cdDrop1;
            if(ccRotate == true){
                this.rotateIcon = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon = 'iv-ui-svg-icon';
            }
        },
        cldDrop2(){
            this.cdDrop2 = !this.cdDrop2;
            let ccRotate = this.cdDrop2;
            if(ccRotate == true){
                this.rotateIcon2 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon2 = 'iv-ui-svg-icon';
            }
        },
        cldDrop3(){
            this.cdDrop3 = !this.cdDrop3;
            let ccRotate = this.cdDrop3;
            if(ccRotate == true){
                this.rotateIcon3 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon3 = 'iv-ui-svg-icon';
            }
        },
        cldDrop4(){
            this.cdDrop4 = !this.cdDrop4;
            let ccRotate = this.cdDrop4;
            if(ccRotate == true){
                this.rotateIcon4 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon4 = 'iv-ui-svg-icon';
            }
        },
        cldDrop5(){
            this.cdDrop5 = !this.cdDrop5;
            let ccRotate = this.cdDrop5;
            if(ccRotate == true){
                this.rotateIcon5 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon5 = 'iv-ui-svg-icon';
            }
        },
        cldDrop6(){
            this.cdDrop6 = !this.cdDrop6;
            let ccRotate = this.cdDrop6;
            if(ccRotate == true){
                this.rotateIcon6 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon6 = 'iv-ui-svg-icon';
            }
        },
        cldDrop7(){
            this.cdDrop7 = !this.cdDrop7;
            let ccRotate = this.cdDrop7;
            if(ccRotate == true){
                this.rotateIcon7 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon7 = 'iv-ui-svg-icon';
            }
        },
        cldDrop8(){
            this.cdDrop8 = !this.cdDrop8;
            let ccRotate = this.cdDrop8;
            if(ccRotate == true){
                this.rotateIcon8 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon8 = 'iv-ui-svg-icon';
            }
        },
        cldDrop9(){
            this.cdDrop9 = !this.cdDrop9;
            let ccRotate = this.cdDrop9;
            if(ccRotate == true){
                this.rotateIcon9 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon9 = 'iv-ui-svg-icon';
            }
        },
        cldDrop10(){
            this.cdDrop10 = !this.cdDrop10;
            let ccRotate = this.cdDrop10;
            if(ccRotate == true){
                this.rotateIcon10 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon10 = 'iv-ui-svg-icon';
            }
        },
        cldDrop11(){
            this.cdDrop11 = !this.cdDrop11;
            let ccRotate = this.cdDrop11;
            if(ccRotate == true){
                this.rotateIcon = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon = 'iv-ui-svg-icon';
            }
        },
        cldDrop12(){
            this.cdDrop12 = !this.cdDrop12;
            let ccRotate = this.cdDrop12;
            if(ccRotate == true){
                this.rotateIcon12 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon12 = 'iv-ui-svg-icon';
            }
        },
        cldDrop13(){
            this.cdDrop13 = !this.cdDrop13;
            let ccRotate = this.cdDrop13;
            if(ccRotate == true){
                this.rotateIcon13 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon13 = 'iv-ui-svg-icon';
            }
        },
        cldDrop14(){
            this.cdDrop14 = !this.cdDrop14;
            let ccRotate = this.cdDrop14;
            if(ccRotate == true){
                this.rotateIcon14 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon14 = 'iv-ui-svg-icon';
            }
        },
        cldDrop15(){
            this.cdDrop15 = !this.cdDrop15;
            let ccRotate = this.cdDrop15;
            if(ccRotate == true){
                this.rotateIcon15 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon15 = 'iv-ui-svg-icon';
            }
        },
        cldDrop16(){
            this.cdDrop16 = !this.cdDrop16;
            let ccRotate = this.cdDrop16;
            if(ccRotate == true){
                this.rotateIcon16 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon16 = 'iv-ui-svg-icon';
            }
        },
        cldDrop17(){
            this.cdDrop17 = !this.cdDrop17;
            let ccRotate = this.cdDrop17;
            if(ccRotate == true){
                this.rotateIcon17 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon17 = 'iv-ui-svg-icon';
            }
        },
        cldDrop18(){
            this.cdDrop18 = !this.cdDrop18;
            let ccRotate = this.cdDrop18;
            if(ccRotate == true){
                this.rotateIcon18 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon18 = 'iv-ui-svg-icon';
            }
        },
        cldDrop19(){
            this.cdDrop19 = !this.cdDrop19;
            let ccRotate = this.cdDrop19;
            if(ccRotate == true){
                this.rotateIcon19 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon19 = 'iv-ui-svg-icon';
            }
        },
        onClickClosePopupAskedQuestions(val) {
            this.openPopup = false;
            this.$emit('closePopupAskedQuestions', val)
        }
    },
    created() {
    },
    mounted() {
        const next = this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickClosePopupAskedQuestions, false);
    }
}
</script>

<style lang="scss" scoped>
.iv-ui-popup-dialog__content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
}
.iv-ui-form-content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
}
.iv-ui-form-main-content {
    display: flex;
    flex: 0 1 auto;
    flex-flow: column nowrap;
    padding: 8px 32px 32px;
}
.iv-help-content__body {
    padding: 32px 0 0;
}
.iv-help-section:first-child {
    padding-top: 0;
}
.iv-help-section__title {
    color: #31363f;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
}
.iv-help-section__description {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-top: 8px;
}
.iv-help-section__content {
    padding-top: 16px;
}
.iv-ui-accordion {
    background-color: #fff;
    border: 1px solid rgba(182,188,196,.3);
    border-radius: 8px;
    overflow: hidden;
}
.iv-ui-accordion-expandable-item {
    background-color: inherit;
}
.iv-ui-accordion-item-header {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    height: 50px;
    line-height: 16px;
    padding: 0 24px;
    -webkit-user-select: none;
    user-select: none;
}
.iv-ui-accordion-expandable-item .ReactCollapse--collapse {
    transition: height .15s;
}
.iv-ui-accordion-expandable-item-content {
    border-top: 1px solid rgba(182,188,196,.3);
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    line-height: 140%;
    padding: 15px;
}
.iv-help-item-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.iv-help-item-content-row {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 15px;
}
.iv-help-item-content-image {
    border-radius: 2px;
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
}
.iv-help-item-content-text {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-left: 20px;
}
.iv-help-item-content-text {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-left: 20px;
}
.iv-ui-accordion-expandable-item-content p {
    margin-bottom: 8px;
}
.iv-ui-accordion-expandable-item-content p:last-child {
    margin-bottom: 0;
}
.iv-help-item-content-row {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 24px;
}
.iv-help-item-content-row:last-child {
    padding-bottom: 0;
}
.iv-ui-accordion-expandable-item:not(:first-child) {
    border-top: 1px solid rgba(182,188,196,.3);
}
.iv-help-content__preface {
    border-bottom: 1px solid #e6e8eb;
    box-sizing: border-box;
    padding: 16px 32px;
    text-align: center;
    width: 100%;
}
.iv-help-section {
    padding-top: 32px;
}
.iv-help-section__content ul {
    margin-bottom: 0;
    padding-left: 16px;
    padding-top: 8px;
}
.iv-help-section__content ul li {
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 4px;
}
.iv-ui-my-accordion__header__m-item-expanded .iv-ui-my-accordion__header__text {
    font-weight: 500;
}
.iv-ui-accordion-expandable-item-content {
    border-top: 1px solid rgba(182,188,196,.3);
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    line-height: 140%;
    padding: 15px;
}
.iv-help-item-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.iv-faces-help-connection-means {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 24px;
}
.iv-faces-help-connection-means__preface {
    padding-bottom: 24px;
    width: 100%;
}
.iv-faces-help-connection-means__item {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    padding-right: 40px;
    width: 50%;
}
.iv-faces-help-connection-means__item__title {
    font-weight: 700;
    padding-bottom: 24px;
    width: 100%;
}
.iv-faces-help-connection-means__item__image {
    height: 32px;
    line-height: 32px;
    max-width: 360px;
    padding-bottom: 54px;
    text-align: center;
}
.iv-faces-help-connection-means__item__text {
    flex: 1 1 auto;
    padding-bottom: 16px;
    width: 100%;
}
.iv-ui-svg-icon-active {
    margin: auto;
    width: 200px;
    height: 100px;
    color: white;
    transform: rotate(180deg);
    animation: mymove 5s infinite;
}
.iv-ui-accordion-item-header:hover {
    background-color: rgba(182,188,196,.1);
}
.iv-ui-accordion-item-header {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    height: 50px;
    line-height: 16px;
    padding: 0 24px;
    -webkit-user-select: none;
    user-select: none;
}
.iv-ui-svg-icon-active {
    margin: auto;
    width: 200px;
    height: 100px;
    color: white;
    transform: rotate(180deg);
    animation: mymove 5s infinite;
}
</style>

<style lang="scss">
.getHelp .vs-popup {
    width: 900px;
    height: auto;
}
</style>