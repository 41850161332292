<template>
    <div>
        <vs-popup class="camera" :title="$t('getHelpr.titleFaceRecognition')" :active.camel="openPopup">
            <div class="iv-ui-popup-dialog__content">
                <div class="iv-ui-form-content">
                    <div class="iv-ui-form-content__top-content"></div>
                    <div class="iv-ui-form-main-content">
                        <div class="iv-help-content">
                            <div class="iv-help-content__preface"><span>{{$t('getHelpFaceRecognition.header')}}<a
                                href="mailto:report.intface@gmail.com" class="iv-link">{{$t('getHelpFaceRecognition.headerContact')}}</a></span></div>
                            <div class="iv-help-content__body">
                                <div class="iv-help-section">
                                    <div class="iv-help-section__title">{{$t('getHelpFaceRecognition.tabs1')}}</div>
                                    <div class="iv-help-section__description">{{$t('getHelpFaceRecognition.tabs1Info')}}
                                    </div>
                                    <div class="iv-help-section__content">
                                        <div class="iv-ui-accordion">
                                            <div   class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop1"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs1Detail1')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop1" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-face-vo"
                                                                             style="width: 100px; height: 100px">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#FF4747"></circle><path
                                                                                d="m8 16 8-8M16 16 8 8" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-img1"
                                                                             style="width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#0FDC75"></circle><path
                                                                                d="m8 12 3 3 6-6" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs1Detail1Info1')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs1Detail1Info11')}} <b>{{$t('getHelpFaceRecognition.number200')}}</b>&nbsp;{{$t('getHelpFaceRecognition.tabs1Detail1Info111')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-face-mo"
                                                                             style=" width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#FF4747"></circle><path
                                                                                d="m8 16 8-8M16 16 8 8" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-img1"
                                                                             style=" width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#0FDC75"></circle><path
                                                                                d="m8 12 3 3 6-6" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs1Detail1Info2')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs1Detail1Info22')}} <b>{{$t('getHelpFaceRecognition.sharp')}}</b>{{$t('getHelpFaceRecognition.tabs1Detail1Info222')}}</p></span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-face-night"
                                                                             style="width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#FF4747"></circle><path
                                                                                d="m8 16 8-8M16 16 8 8" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-img1"
                                                                             style=" width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#0FDC75"></circle><path
                                                                                d="m8 12 3 3 6-6" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs1Detail1Info3')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs1Detail1Info33')}} <b>{{$t('getHelpFaceRecognition.evenlylist')}}</b>{{$t('getHelpFaceRecognition.tabs1Detail1Info333')}}</p>
</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop2"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs1Detaiil2')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon2" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop2" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-face-center"
                                                                             style="width: 100px; height: 100px;"></div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-image ">
                                                                        <div class="iv-help-image facereco-face-left"
                                                                             style=" width: 100px; height: 100px;"></div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-face-right"
                                                                             style=" width: 100px; height: 100px;"></div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.threeAngles')}}</b></p>
<p>{{$t('getHelpFaceRecognition.youNeed')}}<b>{{$t('getHelpFaceRecognition.facingForward')}}</b>{{$t('getHelpFaceRecognition.thatWillHelp')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-face-vo"
                                                                             style=" width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#FF4747"></circle><path
                                                                                d="m8 16 8-8M16 16 8 8" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-image facereco-face-mo">
                                                                        <div class="iv-help-image"
                                                                             style="width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon"
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#FF4747"></circle><path
                                                                                d="m8 16 8-8M16 16 8 8" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-image">
                                                                        <div class="iv-help-image facereco-face-night"
                                                                             style=" width: 100px; height: 100px;">
                                                                            <div class="iv-help-image__icon"><span
                                                                                class="iv-ui-svg-icon "
                                                                                style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                                width="24" height="24"
                                                                                viewBox="0 0 24 24" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><circle
                                                                                cx="12" cy="12" r="12"
                                                                                fill="#FF4747"></circle><path
                                                                                d="m8 16 8-8M16 16 8 8" stroke="#fff"
                                                                                stroke-width="2"></path></svg></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.pictureQuality')}}</b></p>
<p>{{$t('getHelpFaceRecognition.pictureQuality1')}}<b>{{$t('getHelpFaceRecognition.pictureQuality2')}}</b>{{$t('getHelpFaceRecognition.pictureQuality3')}}<b>{{$t('getHelpFaceRecognition.pictureQuality4')}}</b>{{$t('getHelpFaceRecognition.pictureQuality5')}}<b>{{$t('getHelpFaceRecognition.pictureQuality6')}}</b>.</p>
</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="iv-help-section">
                                    <div class="iv-help-section__title">{{$t('getHelpFaceRecognition.tabs2')}}</div>
                                    <div class="iv-help-section__description">{{$t('getHelpFaceRecognition.tabs2s')}}
                                    </div>
                                    <div class="iv-help-section__content">
                                        <div class="iv-ui-accordion">
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop3"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpFaceRecognition.tabs2D1')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon3" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop3" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                         <img src="@/assets/images/portrait/svg1.svg">
                                                                    </span>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D1i1')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D1i2')}}<b>{{$t('getHelpFaceRecognition.tabs2D1i3')}}</b>{{$t('getHelpFaceRecognition.tabs2D1i4')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                         <img src="@/assets/images/portrait/svg2.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D1i12')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D1i22')}}<b>{{$t('getHelpFaceRecognition.tabs2D1i32')}}</b>{{$t('getHelpFaceRecognition.tabs2D1i42')}}</p>
</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop4"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpFaceRecognition.tabs2D2')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon4" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop4" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                           <img src="@/assets/images/portrait/svg3.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D2i1')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D2i2')}}<b>{{$t('getHelpFaceRecognition.tabs2D2i3')}}</b>{{$t('getHelpFaceRecognition.tabs2D2i4')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                          <img src="@/assets/images/portrait/svg4.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D2i12')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D2i22')}}<b>{{$t('getHelpFaceRecognition.tabs2D2i32')}}</b>{{$t('getHelpFaceRecognition.tabs2D2i42')}}</p>
</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop5"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpFaceRecognition.tabs2D3')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon5"  class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop5" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                        <img src="@/assets/images/portrait/svg5.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D3')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D3i2')}}<b>{{$t('getHelpFaceRecognition.tabs2D3i3')}}</b>{{$t('getHelpFaceRecognition.tabs2D3i4')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                           <img src="@/assets/images/portrait/svg6.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D3i12')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D3i22')}}<b>{{$t('getHelpFaceRecognition.tabs2D3i32')}}</b>{{$t('getHelpFaceRecognition.tabs2D3i42')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                          <img src="@/assets/images/portrait/svg7.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D3i13')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D3i23')}}<b>{{$t('getHelpFaceRecognition.tabs2D3i33')}}</b>{{$t('getHelpFaceRecognition.tabs2D3i43')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                        <img src="@/assets/images/portrait/svg8.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D3i14')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D3i24')}}<b>{{$t('getHelpFaceRecognition.tabs2D3i34')}}</b>.</p>
</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop6"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs2D4')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon6" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop6" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                        <img src="@/assets/images/portrait/demo.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D4i1')}}<span class="iv-nowrap"></span></b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D4i2')}}<b>{{$t('getHelpFaceRecognition.tabs2D4i3')}}</b>{{$t('getHelpFaceRecognition.tabs2D4i4')}}</p>
</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">
                                                                        <img src="@/assets/images/portrait/svg10.svg">
                                                                    </span></div>
                                                                    <div class="iv-help-item-content-text"><span>
<p><b>{{$t('getHelpFaceRecognition.tabs2D4i12')}}</b></p>
<p>{{$t('getHelpFaceRecognition.tabs2D4i22')}}<b>{{$t('getHelpFaceRecognition.tabs2D4i32')}}</b>.</p>
</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="iv-help-section">
                                    <div class="iv-help-section__title">{{$t('getHelpFaceRecognition.tabs3')}}</div>
                                    <div class="iv-help-section__content">
                                        <div class="iv-ui-accordion">
                                          <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop7"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D1')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon7" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop7" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D1i2')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D1i3')}}
    </li>
</ul>
</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop8"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D2')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon8" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop8" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D2i1')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D2i2')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D2i3')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D2i4')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D2i5')}}</li>
</ul>
</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop9"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D3')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon9" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop9" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<p>{{$t('getHelpFaceRecognition.tabs3D3i1')}}</p>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D3i2')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D3i3')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D3i4')}}</li>
</ul>
</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop10"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D4')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon10" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop10" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<p>{{$t('getHelpFaceRecognition.tabs3D4i1')}}</p>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D4i2')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D4i3')}}</li>
</ul></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop11"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D5')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon11" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop11" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<p>{{$t('getHelpFaceRecognition.tabs3D5i1')}}</p>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D5i4')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D5i2')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D5i3')}}</li>
</ul>
</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop12"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D6')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon12" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop12" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<p></p>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D6i1')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D6i2')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D6i3')}}</li>
</ul>
</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop13"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D7')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon13" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop13" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D7i1')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D7i2')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D7i3')}}</li>
</ul>
</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop14"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpFaceRecognition.tabs3D8')}}</span>
                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon14" class="iv-ui-svg-icon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  v-if="cdDrop14" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>
<b>{{$t('getHelpFaceRecognition.tabs3D1i1')}}</b>
<ul>
<li>{{$t('getHelpFaceRecognition.tabs3D8i1')}}</li>
<li>{{$t('getHelpFaceRecognition.tabs3D8i2')}}</li>
</ul>
</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="iv-ui-form-content__footer-content"></div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import vSelect from 'vue-select';


export default {
    components: {
        vSelect
    },
    data() {
        return {
            image1s: '../../../../assets/images/portrait/anh.jpg',
            cdDrop1: false, cdDrop2: false,  cdDrop3: false, cdDrop4: false, cdDrop5: false,cdDrop6: false,  cdDrop7: false, cdDrop8: false, cdDrop9: false, cdDrop10: false, cdDrop11: false,cdDrop12: false,cdDrop13: false,cdDrop14: false,cdDrop15: false,
            rotateIcon: 'iv-ui-svg-icon',rotateIcon2: 'iv-ui-svg-icon',rotateIcon3: 'iv-ui-svg-icon',rotateIcon4: 'iv-ui-svg-icon',rotateIcon5: 'iv-ui-svg-icon',rotateIcon6: 'iv-ui-svg-icon', rotateIcon7: 'iv-ui-svg-icon',rotateIcon8: 'iv-ui-svg-icon',rotateIcon9: 'iv-ui-svg-icon',rotateIcon10: 'iv-ui-svg-icon',rotateIcon11: 'iv-ui-svg-icon',rotateIcon12: 'iv-ui-svg-icon',rotateIcon13: 'iv-ui-svg-icon',rotateIcon14: 'iv-ui-svg-icon',rotateIcon15: 'iv-ui-svg-icon',
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        closePopup: {
            type: Function
        },
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        cldDrop1(){
            this.cdDrop1 = !this.cdDrop1;
            let ccRotate = this.cdDrop1;
            if(ccRotate == true){
                this.rotateIcon = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon = 'iv-ui-svg-icon';
            }
        },
        cldDrop2(){
            this.cdDrop2 = !this.cdDrop2;
            let ccRotate = this.cdDrop2;
            if(ccRotate == true){
                this.rotateIcon2 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon2 = 'iv-ui-svg-icon';
            }
        },
        cldDrop3(){
            this.cdDrop3 = !this.cdDrop3;
            let ccRotate = this.cdDrop3;
            if(ccRotate == true){
                this.rotateIcon3 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon3 = 'iv-ui-svg-icon';
            }
        },
        cldDrop4(){
            this.cdDrop4 = !this.cdDrop4;
            let ccRotate = this.cdDrop4;
            if(ccRotate == true){
                this.rotateIcon4 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon4 = 'iv-ui-svg-icon';
            }
        },
        cldDrop5(){
            this.cdDrop5 = !this.cdDrop5;
            let ccRotate = this.cdDrop5;
            if(ccRotate == true){
                this.rotateIcon5 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon5 = 'iv-ui-svg-icon';
            }
        },
        cldDrop6(){
            this.cdDrop6 = !this.cdDrop6;
            let ccRotate = this.cdDrop6;
            if(ccRotate == true){
                this.rotateIcon6 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon6 = 'iv-ui-svg-icon';
            }
        },
        cldDrop7(){
            this.cdDrop7 = !this.cdDrop7;
            let ccRotate = this.cdDrop7;
            if(ccRotate == true){
                this.rotateIcon7 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon7 = 'iv-ui-svg-icon';
            }
        },
        cldDrop8(){
            this.cdDrop8 = !this.cdDrop8;
            let ccRotate = this.cdDrop8;
            if(ccRotate == true){
                this.rotateIcon8 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon8 = 'iv-ui-svg-icon';
            }
        },
        cldDrop9(){
            this.cdDrop9 = !this.cdDrop9;
            let ccRotate = this.cdDrop9;
            if(ccRotate == true){
                this.rotateIcon9 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon9 = 'iv-ui-svg-icon';
            }
        },
        cldDrop10(){
            this.cdDrop10 = !this.cdDrop10;
            let ccRotate = this.cdDrop10;
            if(ccRotate == true){
                this.rotateIcon10 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon10 = 'iv-ui-svg-icon';
            }
        },
        cldDrop11(){
            this.cdDrop11 = !this.cdDrop11;
            let ccRotate = this.cdDrop11;
            if(ccRotate == true){
                this.rotateIcon11 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon11 = 'iv-ui-svg-icon';
            }
        },
        cldDrop12(){
            this.cdDrop12 = !this.cdDrop12;
            let ccRotate = this.cdDrop12;
            if(ccRotate == true){
                this.rotateIcon12 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon12 = 'iv-ui-svg-icon';
            }
        },
        cldDrop13(){
            this.cdDrop13 = !this.cdDrop13;
            let ccRotate = this.cdDrop13;
            if(ccRotate == true){
                this.rotateIcon13 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon13 = 'iv-ui-svg-icon';
            }
        },
        cldDrop14(){
            this.cdDrop14 = !this.cdDrop14;
            let ccRotate = this.cdDrop14;
            if(ccRotate == true){
                this.rotateIcon14 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon14 = 'iv-ui-svg-icon';
            }
        },
        cldDrop15(){
            this.cdDrop15 = !this.cdDrop15;
            let ccRotate = this.cdDrop15;
            if(ccRotate == true){
                this.rotateIcon15 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon15 = 'iv-ui-svg-icon';
            }
        },


        onClickCloseFaceRecognition(val) {
            this.openPopup = false;
            this.$emit('closePopupFaceRecognition', val)
        }
    },
    created() {
    },
    mounted() {
        const next = this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseFaceRecognition, false);
    }
}
</script>

<style lang="scss" scoped>
.iv-ui-popup-dialog__content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
}
.iv-ui-form-content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
}
.iv-ui-form-main-content {
    display: flex;
    flex: 0 1 auto;
    flex-flow: column nowrap;
    padding: 8px 32px 32px;
}
.iv-help-content__body {
    padding: 32px 0 0;
}
.iv-help-section:first-child {
    padding-top: 0;
}
.iv-help-section__title {
    color: #31363f;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
}
.iv-help-section__description {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-top: 8px;
}
.iv-help-section__content {
    padding-top: 16px;
}
.iv-ui-accordion {
    background-color: #fff;
    border: 1px solid rgba(182,188,196,.3);
    border-radius: 8px;
    overflow: hidden;
}
.iv-ui-accordion-expandable-item {
    background-color: inherit;
}
.iv-ui-accordion-item-header {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    height: 50px;
    line-height: 16px;
    padding: 0 24px;
    -webkit-user-select: none;
    user-select: none;
}
.iv-ui-accordion-expandable-item .ReactCollapse--collapse {
    transition: height .15s;
}
.iv-ui-accordion-expandable-item-content {
    border-top: 1px solid rgba(182,188,196,.3);
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    line-height: 140%;
    padding: 24px;
}
.iv-help-item-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.iv-help-item-content-row {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 24px;
}
.iv-help-item-content-image {
    border-radius: 2px;
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
}
.iv-help-item-content-text {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-left: 20px;
}
.iv-help-item-content-text {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-left: 20px;
}
.iv-ui-accordion-expandable-item-content p {
    margin-bottom: 8px;
}
.iv-ui-accordion-expandable-item-content p:last-child {
    margin-bottom: 0;
}
.iv-help-item-content-row {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 24px;
}
.iv-help-item-content-row:last-child {
    padding-bottom: 0;
}
.iv-ui-accordion-expandable-item:not(:first-child) {
    border-top: 1px solid rgba(182,188,196,.3);
}
.iv-help-content__preface {
    border-bottom: 1px solid #e6e8eb;
    box-sizing: border-box;
    padding: 16px 32px;
    text-align: center;
    width: 100%;
}
.iv-help-section {
    padding-top: 32px;
}
.iv-help-section__content ul {
    margin-bottom: 0;
    padding-left: 16px;
    padding-top: 8px;
}
.iv-help-section__content ul li {
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 4px;
}
.iv-ui-my-accordion__header__m-item-expanded .iv-ui-my-accordion__header__text {
    font-weight: 500;
}
.iv-ui-accordion-expandable-item-content {
    border-top: 1px solid rgba(182,188,196,.3);
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    line-height: 140%;
    padding: 24px;
}
.iv-help-item-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.iv-faces-help-connection-means {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 24px;
}
.iv-faces-help-connection-means__preface {
    padding-bottom: 24px;
    width: 100%;
}
.iv-faces-help-connection-means__item {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    padding-right: 40px;
    width: 50%;
}
.iv-faces-help-connection-means__item__title {
    font-weight: 700;
    padding-bottom: 24px;
    width: 100%;
}
.iv-faces-help-connection-means__item__image {
    height: 32px;
    line-height: 32px;
    max-width: 360px;
    padding-bottom: 54px;
    text-align: center;
}
.iv-faces-help-connection-means__item__text {
    flex: 1 1 auto;
    padding-bottom: 16px;
    width: 100%;
}
.iv-ui-svg-icon-active {
    margin: auto;
    width: 200px;
    height: 100px;
    color: white;
    transform: rotate(180deg);
    animation: mymove 5s infinite;
}
.iv-ui-accordion-item-header:hover {
    background-color: rgba(182,188,196,.1);
}
.iv-ui-accordion-item-header {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    height: 50px;
    line-height: 16px;
    padding: 0 24px;
    -webkit-user-select: none;
    user-select: none;
}
</style>
