<template>
    <div>
        <vs-dropdown class="cursor-pointer"  vs-trigger-click>

<!--            <div class="con-img cursor-pointer block mt-2" style="color: white">-->
<!--                <feather-icon icon="HelpCircleIcon" svgClasses="w-7 h-7"/>-->
<!--            </div>-->
            <div class="con-img mt-1 ml-3 rounded-full cursor-pointer block">
                <svg width="40" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-.882-9.58c-.155.378-.232.886-.232 1.524h1.75l.027-.417c.05-.446.248-.836.595-1.169l.554-.526c.433-.42.736-.8.909-1.142.173-.346.26-.713.26-1.1 0-.852-.267-1.51-.8-1.976-.533-.47-1.283-.704-2.25-.704-.956 0-1.713.246-2.269.738-.551.493-.832 1.174-.84 2.044h1.982c.009-.364.114-.649.314-.854.205-.21.476-.315.814-.315.71 0 1.066.386 1.066 1.156 0 .255-.068.499-.205.731-.137.228-.412.527-.827.896-.41.364-.693.736-.848 1.114zm-.123 2.871a.994.994 0 0 0-.307.752c0 .3.1.55.3.745.205.196.474.294.807.294.333 0 .6-.098.8-.294a.986.986 0 0 0 .307-.745.984.984 0 0 0-.314-.752c-.205-.2-.47-.3-.793-.3-.324 0-.59.1-.8.3z" fill="#ffffff"></path></svg>
            </div>
                    <vs-dropdown-menu v-if="ofDropdown" title='Get Help' class="vx-navbar-dropdown">
                        <div v-if="activeTabs"
                            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            @click="$router.push('/user/api_docs').catch(() => {})">
                            <feather-icon icon="BookIcon" svgClasses="w-4 h-4"/>
                            <span class="ml-2">{{$t('getHelpr.getDocument')}}</span>
                        </div>
                        <vs-divider  class="m-1"/>
                        <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            @click="clickAskedQuestions">
                            <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4"/>
                            <span class="ml-2">{{$t('getHelpAskedQuestions.text1')}}</span>
                        </li>
                        <div class="flex py-2 px-4 ml-1 mt-5 mb-3" style="font-size: 11px">{{$t('getHelpr.analy')}}</div>
                        <ul style="min-width: 17rem">
                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                                @click="clickFaceRecognition">
                                <feather-icon icon="SmileIcon" svgClasses="w-4 h-4"/>
                                <span class="ml-2">{{$t('getHelpr.faceRecognition')}}</span>
                            </li>

                            <vs-divider  class="m-1"/>

                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                               @click="clickCountingVisitors">
                                <feather-icon icon="TrelloIcon" svgClasses="w-4 h-4"/>
                                <span class="ml-2">{{$t('getHelpr.countingVisitors')}}</span>
                            </li>

                            <vs-divider class="m-1"/>

                        </ul>
                    </vs-dropdown-menu>

        </vs-dropdown>
        <asked-questions :openPopup="showAskedQuestions" :closePopup="closePopup"
                         @closePopupAskedQuestions="closePopupAskedQuestions"></asked-questions>
        <face-recognition :openPopup="showPopupFaceRecognition" :closePopup="closePopup"
                          @closePopupFaceRecognition="closePopupFaceRecognition">
        </face-recognition>
        <counting-visitors :openPopup="showPopupCountingVisitors" :closePopup="closePopup"
                           @closePopupCountingVisitors="closePopupCountingVisitors">
        </counting-visitors>

    </div>

</template>

<script>
import FaceRecognition from "./FaceRecognition";
import CountingVisitors from "./CountingVisitors";
import AskedQuestions from "./AskedQuestions";

export default {
    components: {
        AskedQuestions,
        CountingVisitors,
        FaceRecognition
    },
    data() {
        return {
            activeTabs: false,
            ofDropdown: true,
            showPopupFaceRecognition: false,
            showPopupCountingVisitors: false,
            showAskedQuestions: false,
        }
    },
    created() {
        if (this.$router.currentRoute.meta.rule == 'admin') {
           this.activeTabs =  false;
        } else {
            this.activeTabs = true
        }
    },
    methods: {
        clickAskedQuestions(){
            this.showAskedQuestions = true;
            this.ofDropdown = false;
        },

        clickFaceRecognition(){
            this.showPopupFaceRecognition = true;
            this.ofDropdown = false;
        },

        clickCountingVisitors(){
            this.showPopupCountingVisitors = true;
            this.ofDropdown = false;
        },

        closePopup: function () {
            this.showPopupFaceRecognition = false;
            this.ofDropdown = true;
        },

        closePopupFaceRecognition() {
            this.showPopupFaceRecognition = false;
            this.ofDropdown = true;
        },

        closePopupCountingVisitors(){
            this.showPopupCountingVisitors = false;
            this.ofDropdown = true
        },

        closePopupAskedQuestions(){
            this.showAskedQuestions = false;
            this.ofDropdown = true
        }
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>

