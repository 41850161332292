<template>
    <!-- NOTIFICATIONS -->
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <feather-icon style="color:white" icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="unreadNotificationsNum"/>

        <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

            <div class="notification-top text-center p-5 bg-primary text-white">
                <h3 class="text-white">{{ unreadNotificationsNum }} Thông báo chưa đọc</h3>
            </div>

            <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10"
                                 :settings="settings" :key="$vs.rtl">
                <ul class="bordered-items">
                    <li @click="openUrl(ntf)" v-for="ntf in unreadNotifications" :key="ntf.index" :style="ntf.style"
                        class="flex justify-between px-4 py-4 notification cursor-pointer">
                        <div class="flex items-start">
                            <feather-icon :icon="ntf.icon"
                                          :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
                            <div class="mx-2">
                                <span class="font-medium block notification-title"
                                      :class="[`text-${ntf.category}`]">{{ ntf.title }}</span>
                                <small>{{ ntf.msg }}</small>
                            </div>
                        </div>
                        <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.createdDate) }}</small>
                    </li>
                </ul>
            </VuePerfectScrollbar>

            <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer">
                <!-- <span>View All Notifications</span> -->
            </div>
        </vs-dropdown-menu>
    </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        VuePerfectScrollbar
    },
    data() {
        return {
            selectedNtf: {},
            unreadNotifications: [],
            unreadNotificationsNum: 0,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .60,
            },
        }
    },
    methods: {
        openUrl(ntf) {
            let body = JSON.parse(ntf.body)
            this.$crm.post('/notification/seen/' + ntf.id).then((response) => {
            }).catch((err) => {
            }).finally(() => {
                let url = body.screenUrl;
                if (url && url.trim().length) {
                    url = url + "?" + this.buildUrlParams(body.params)
                    location.href = url
                } else {
                    setTimeout(() => {
                        this.loadNotifications();
                    }, 500)
                }
            });
            this.$crm.post('/notification/seen/' + body.id);
        },
        openUrlInPopup() {
            let body = JSON.parse(this.selectedNtf.data.body)
            this.$crm.post('/notification/seen/' + body.id).then((response) => {
            }).catch((err) => {
            }).finally(() => {
                let url = body.screenUrl;
                if (url && url.trim().length) {
                    url = url + "?" + this.buildUrlParams(body.params)
                    location.href = url
                } else {
                    setTimeout(() => {
                        this.loadNotifications();
                    }, 500)
                }
            });
        },
        buildUrlParams(urlParams) {
            let str = "";
            Object.keys(urlParams).forEach(key => {
                if (key == "id") str += "id=" + urlParams[key] + "&";
                if (key == "organizationBranchId") str += "branch_id=" + urlParams[key] + "&";
            })
            return str;
        },
        elapsedTime(startTime) {
            let x = new Date(startTime)
            let now = new Date()
            var timeDiff = now - x
            timeDiff /= 1000

            var seconds = Math.round(timeDiff)
            timeDiff = Math.floor(timeDiff / 60)

            var minutes = Math.round(timeDiff % 60)
            timeDiff = Math.floor(timeDiff / 60)

            var hours = Math.round(timeDiff % 24)
            timeDiff = Math.floor(timeDiff / 24)

            var days = Math.round(timeDiff % 365)
            timeDiff = Math.floor(timeDiff / 365)

            var years = timeDiff

            if (years > 0) {
                return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
            } else if (days > 0) {
                return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
            } else if (hours > 0) {
                return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
            } else if (minutes > 0) {
                return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
            } else if (seconds > 0) {
                return (seconds > 1 ? seconds + ' sec ago' : 'Just now')
            }

            return 'Just Now'
        },
        // Method for creating dummy notification time
        loadNotifications() {
            this.$crm.post('/notification/list', {}).then((response) => {
                this.$vs.loading.close();
                this.unreadNotifications = response.data.map(item => {
                    item.msg = JSON.parse(item.body).content;
                    item.style = {
                        'background-color': item.seenStatus == 0 ? '#edf2fa' : '#fff'
                    }
                    return item;
                });
                this.unreadNotificationsNum = this.unreadNotifications.reduce((unreadNum, item) => {
                    if (item.seenStatus == 0) unreadNum++;
                    return unreadNum;
                }, 0)
            }).catch(() => {
                this.$vs.loading.close();
            });
        }
    },
    created() {
        this.loadNotifications();
        if (this.$messaging) {
            this.$messaging.onMessage((payload) => {
                if (payload.notification.title.includes('in_out_customer')) {
                    let data = JSON.parse(payload.data.body);
                    this.$eventBus.$emit('realtime-customer', data.detections[0])
                }
                if (payload.data.type != "user") return
                this.selectedNtf = payload;
                setTimeout(() => {
                    this.loadNotifications()
                }, 500)
                return this.$vs.notify({
                    text: payload.notification.title,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success',
                    click: this.openUrlInPopup

                });
            });
        }
    }
}

</script>

