<template>
    <div>

        <vs-popup class="camera" :title="$t('getHelpr.titleCountingVisitors')" :active.camel="openPopup">

            <div class="iv-ui-popup-dialog__content">
                <div class="iv-ui-form-content">
                    <div class="iv-ui-form-content__top-content"></div>
                    <div class="iv-ui-form-main-content">
                        <div class="iv-help-content">

                            <div class="iv-help-content__preface"><span>{{$t('getHelpCountingVisitors.line1')}}<a
                                href="mailto:report.intface@gmail.com" class="iv-link">{{$t('getHelpCountingVisitors.line2')}}</a></span></div>
                            <div class="iv-help-content__body">
                                <div class="iv-help-section">
                                    <div class="iv-help-section__title">{{$t('getHelpCountingVisitors.line3')}}</div>
                                    <div class="iv-help-section__description">{{$t('getHelpCountingVisitors.line4')}}
                                    </div>
                                    <div class="iv-help-section__content">
                                        <div class="iv-ui-accordion">
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop1"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpCountingVisitors.line5')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop1" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg1.svg"></span>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span><p><b>{{$t('getHelpCountingVisitors.line6')}}</b></p><p>{{$t('getHelpCountingVisitors.line7')}}<b>{{$t('getHelpCountingVisitors.line8')}}</b>.</p></span>
                                                                    </div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg7.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span><p><b>{{$t('getHelpCountingVisitors.line9')}}</b></p><p>{{$t('getHelpCountingVisitors.line10')}}<b>{{$t('getHelpCountingVisitors.line11')}}</b>{{$t('getHelpCountingVisitors.line12')}}</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop2"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpCountingVisitors.line13')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon2"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop2" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg11.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line14')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line15')}}<b>{{$t('getHelpCountingVisitors.line16')}}</b>{{$t('getHelpCountingVisitors.line17')}}<b>{{$t('getHelpCountingVisitors.line18')}}</b>{{$t('getHelpCountingVisitors.line19')}}<b> {{$t('getHelpCountingVisitors.line20')}}</b>.</p>
		<p>{{$t('getHelpCountingVisitors.line21')}}<b>{{$t('getHelpCountingVisitors.line22')}}</b></p>

	</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg7.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line23')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line24')}}<b>{{$t('getHelpCountingVisitors.line25')}}</b>.</p>
		<p>{{$t('getHelpCountingVisitors.line26')}}<b>{{$t('getHelpCountingVisitors.line27')}}</b>{{$t('getHelpCountingVisitors.line28')}}</p>
		<p>{{$t('getHelpCountingVisitors.line29')}}</p>

	</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg12.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line30')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line31')}}<b>{{$t('getHelpCountingVisitors.line32')}}</b>{{$t('getHelpCountingVisitors.line33')}}</p>

	</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop3"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">

                                                        <span>{{$t('getHelpCountingVisitors.line34')}}</span></div>

                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon3"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop3"  class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg13.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span>
		<p><b>{{$t('getHelpCountingVisitors.line35')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line36')}}<b>{{$t('getHelpCountingVisitors.line37')}}</b>.</p>

	</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop4"
                                                     class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpCountingVisitors.line38')}}</span></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon4"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop4" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg14.svg"></span>
                                                                    </div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line39')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line40')}}<b>{{$t('getHelpCountingVisitors.line41')}}</b>{{$t('getHelpCountingVisitors.line42')}}</p>

	</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg15.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line43')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line44')}}<b>{{$t('getHelpCountingVisitors.line45')}}</b>{{$t('getHelpCountingVisitors.line46')}}</p>


	</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg16.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line47')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line48')}}<b>{{$t('getHelpCountingVisitors.line49')}}</b>{{$t('getHelpCountingVisitors.line50')}}</p>

	</span></div>
                                                                </div>
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 100px; height: 100px;">  <img src="@/assets/images/portrait/svg17.svg"></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line51')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line52')}} <b>{{$t('getHelpCountingVisitors.line53')}}</b>{{$t('getHelpCountingVisitors.line54')}}</p>

	</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="iv-help-section">

                                    <div class="iv-help-section__title">{{$t('getHelpCountingVisitors.line55')}}</div>

                                    <div class="iv-help-section__content">
                                        <div class="iv-ui-accordion">
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop5"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">
                                                        <span>{{$t('getHelpCountingVisitors.line56')}}</span></div>

                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon5"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop5" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>

		<p><b>{{$t('getHelpCountingVisitors.line57')}}</b></p>
		<ul>
			<li>{{$t('getHelpCountingVisitors.line58')}}</li>
			<li>{{$t('getHelpCountingVisitors.line59')}}</li>
			<li>{{$t('getHelpCountingVisitors.line60')}}</li>
			<li>{{$t('getHelpCountingVisitors.line61')}}</li>
			<li>{{$t('getHelpCountingVisitors.line62')}}</li>
			<li>{{$t('getHelpCountingVisitors.line63')}}</li>
		</ul>
	</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop6"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">

                                                        <span>{{$t('getHelpCountingVisitors.line82')}}</span></div>

                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon6"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop6" lass="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="M13 14h-2v-4h2v4zm0 4h-2v-2h2v2zM1 21h22L12 2 1 21z"
                                                                        fill="#FF6868"></path></svg></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line83')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line84')}}</p>
		<br>
		<p><b>{{$t('getHelpCountingVisitors.line85')}}</b></p>
		<ul>
			<li>{{$t('getHelpCountingVisitors.line86')}}</li>
			<li>{{$t('getHelpCountingVisitors.line87')}}</li>
			<li>{{$t('getHelpCountingVisitors.line88')}}</li>
			<li>{{$t('getHelpCountingVisitors.line89')}}</li>
			<li>{{$t('getHelpCountingVisitors.line90')}}</li>
		</ul>
	</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop7"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                    <div class="iv-ui-my-accordion__header__text">

                                                        <span>{{$t('getHelpCountingVisitors.line64')}}</span></div>

                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon7"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop7"  class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <div class="iv-help-item-content">
                                                                <div class="iv-help-item-content-row">
                                                                    <div class="iv-help-item-content-image"><span
                                                                        class="iv-ui-svg-icon"
                                                                        style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="M13 14h-2v-4h2v4zm0 4h-2v-2h2v2zM1 21h22L12 2 1 21z"
                                                                        fill="#FF6868"></path></svg></span></div>
                                                                    <div class="iv-help-item-content-text"><span>

		<p><b>{{$t('getHelpCountingVisitors.line65')}}</b></p>
		<p>{{$t('getHelpCountingVisitors.line66')}}</p>
		<br>
		<p><b>{{$t('getHelpCountingVisitors.line67')}}</b></p>
		<ul>
			<li>{{$t('getHelpCountingVisitors.line68')}}</li>
			<li>{{$t('getHelpCountingVisitors.line69')}}</li>
			<li>{{$t('getHelpCountingVisitors.line70')}}</li>
			<li>{{$t('getHelpCountingVisitors.line71')}}</li>
			<li>{{$t('getHelpCountingVisitors.line72')}}</li>
			<li>{{$t('getHelpCountingVisitors.line73')}}</li>
		</ul>
	</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="iv-help-section">

                                    <div class="iv-help-section__title">{{$t('getHelpCountingVisitors.line74')}}</div>
                                    <div class="iv-help-section__content">
                                        <div class="iv-ui-accordion">
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop8"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>

                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpCountingVisitors.line75')}}<span
                                                        class="iv-nowrap"></span></span></div>

                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon8"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop8" class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>

		<p>{{$t('getHelpCountingVisitors.line76')}}
			<span
            class="iv-nowrap"></span>.
			<br>{{$t('getHelpCountingVisitors.line77')}}

		</p>
	</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="iv-ui-accordion-expandable-item">
                                                <div @click="cldDrop9"
                                                    class="iv-ui-accordion-item-header iv-ui-my-accordion__header iv-ui-my-accordion__header__m-item-expanded">
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>

                                                    <div class="iv-ui-my-accordion__header__text"><span>{{$t('getHelpCountingVisitors.line78')}}</span>

                                                    </div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 1 1 auto; -webkit-box-flex: 1;"></div>
                                                    <div class="iv-ui-flex-item"
                                                         style="flex: 0 0 auto; width: 32px; -webkit-box-flex: 0;">
                                                        <div
                                                            class="iv-ui-alignment iv-ui-alignment__m-horizontal-center">
                                                            <div
                                                                class="iv-ui-alignment__content iv-ui-alignment__content__m-vertical-center"
                                                                style="max-width: 100%;">
                                                                <div class="iv-ui-my-accordion__header__toggle-icon">
                                                                    <span :class="rotateIcon9"
                                                                          style="position: relative; display: inline-block; vertical-align: top; width: 24px; height: 24px;"><svg
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                                        d="m6 9 6 6 6-6" stroke="#A5AAB2"
                                                                        stroke-width="2"></path></svg></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="cdDrop9"  class="ReactCollapse--collapse" aria-hidden="false"
                                                     style="height: auto; overflow: initial;">
                                                    <div class="ReactCollapse--content">
                                                        <div class="iv-ui-accordion-expandable-item-content">
                                                            <div class="iv-ui-flex-item"
                                                                 style="flex: 0 0 auto; width: 0px; -webkit-box-flex: 0;"></div>
                                                            <span>

		<p>{{$t('getHelpCountingVisitors.line79')}}
			 <span class="iv-nowrap"></span>{{$t('getHelpCountingVisitors.line80')}}
			<br> {{$t('getHelpCountingVisitors.line81')}}

		</p>
	</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="iv-ui-form-content__footer-content"></div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
    components: {
        vSelect
    },
    data() {
        return {
            cdDrop1: false, cdDrop2: false,  cdDrop3: false, cdDrop4: false, cdDrop5: false,cdDrop6: false,  cdDrop7: false, cdDrop8: false, cdDrop9: false,
            rotateIcon: 'iv-ui-svg-icon',rotateIcon2: 'iv-ui-svg-icon',rotateIcon3: 'iv-ui-svg-icon',rotateIcon4: 'iv-ui-svg-icon',rotateIcon5: 'iv-ui-svg-icon',rotateIcon6: 'iv-ui-svg-icon', rotateIcon7: 'iv-ui-svg-icon',rotateIcon8: 'iv-ui-svg-icon',rotateIcon9: 'iv-ui-svg-icon',
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        closePopup: {
            type: Function
        },
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        cldDrop1(){
            this.cdDrop1 = !this.cdDrop1;
            let ccRotate = this.cdDrop1;
            if(ccRotate == true){
                this.rotateIcon = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon = 'iv-ui-svg-icon';
            }
        },
        cldDrop2(){
            this.cdDrop2 = !this.cdDrop2;
            let ccRotate = this.cdDrop2;
            if(ccRotate == true){
                this.rotateIcon2 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon2 = 'iv-ui-svg-icon';
            }
        },
        cldDrop3(){
            this.cdDrop3 = !this.cdDrop3;
            let ccRotate = this.cdDrop3;
            if(ccRotate == true){
                this.rotateIcon3 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon3 = 'iv-ui-svg-icon';
            }
        },
        cldDrop4(){
            this.cdDrop4 = !this.cdDrop4;
            let ccRotate = this.cdDrop4;
            if(ccRotate == true){
                this.rotateIcon4 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon4 = 'iv-ui-svg-icon';
            }
        },
        cldDrop5(){
            this.cdDrop5 = !this.cdDrop5;
            let ccRotate = this.cdDrop5;
            if(ccRotate == true){
                this.rotateIcon5 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon5 = 'iv-ui-svg-icon';
            }
        },
        cldDrop6(){
            this.cdDrop6 = !this.cdDrop6;
            let ccRotate = this.cdDrop6;
            if(ccRotate == true){
                this.rotateIcon6 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon6 = 'iv-ui-svg-icon';
            }
        },
        cldDrop7(){
            this.cdDrop7 = !this.cdDrop7;
            let ccRotate = this.cdDrop7;
            if(ccRotate == true){
                this.rotateIcon7 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon7 = 'iv-ui-svg-icon';
            }
        },
        cldDrop8(){
            this.cdDrop8 = !this.cdDrop8;
            let ccRotate = this.cdDrop8;
            if(ccRotate == true){
                this.rotateIcon8 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon8 = 'iv-ui-svg-icon';
            }
        },
        cldDrop9(){
            this.cdDrop9 = !this.cdDrop9;
            let ccRotate = this.cdDrop9;
            if(ccRotate == true){
                this.rotateIcon9 = 'iv-ui-svg-icon-active';
            } else {
                this.rotateIcon9 = 'iv-ui-svg-icon';
            }
        },
        onClickCloseCountingVisitors(val) {
            this.openPopup = false;
            this.$emit('closePopupCountingVisitors', val)
        }
    },
    created() {
    },
    mounted() {
        const next = this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseCountingVisitors, false);
    }
}
</script>

<style lang="scss" scoped>
.iv-ui-popup-dialog__content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
}
.iv-ui-form-content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
}
.iv-ui-form-main-content {
    display: flex;
    flex: 0 1 auto;
    flex-flow: column nowrap;
    padding: 8px 32px 32px;
}
.iv-help-content__body {
    padding: 32px 0 0;
}
.iv-help-section:first-child {
    padding-top: 0;
}
.iv-help-section__title {
    color: #31363f;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
}
.iv-help-section__description {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-top: 8px;
}
.iv-help-section__content {
    padding-top: 16px;
}
.iv-ui-accordion {
    background-color: #fff;
    border: 1px solid rgba(182,188,196,.3);
    border-radius: 8px;
    overflow: hidden;
}
.iv-ui-accordion-expandable-item {
    background-color: inherit;
}
.iv-ui-accordion-item-header {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    height: 50px;
    line-height: 16px;
    padding: 0 24px;
    -webkit-user-select: none;
    user-select: none;
}
.iv-ui-accordion-expandable-item .ReactCollapse--collapse {
    transition: height .15s;
}
.iv-ui-accordion-expandable-item-content {
    border-top: 1px solid rgba(182,188,196,.3);
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    line-height: 140%;
    padding: 24px;
}
.iv-help-item-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.iv-help-item-content-row {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 24px;
}
.iv-help-item-content-image {
    border-radius: 2px;
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
}
.iv-help-item-content-text {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-left: 20px;
}
.iv-help-item-content-text {
    color: #31363f;
    font-size: 14px;
    line-height: 140%;
    padding-left: 20px;
}
.iv-ui-accordion-expandable-item-content p {
    margin-bottom: 8px;
}
.iv-ui-accordion-expandable-item-content p:last-child {
    margin-bottom: 0;
}
.iv-help-item-content-row {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 24px;
}
.iv-help-item-content-row:last-child {
    padding-bottom: 0;
}
.iv-ui-accordion-expandable-item:not(:first-child) {
    border-top: 1px solid rgba(182,188,196,.3);
}
.iv-help-content__preface {
    border-bottom: 1px solid #e6e8eb;
    box-sizing: border-box;
    padding: 16px 32px;
    text-align: center;
    width: 100%;
}
.iv-help-section {
    padding-top: 32px;
}
.iv-help-section__content ul {
    margin-bottom: 0;
    padding-left: 16px;
    padding-top: 8px;
}
.iv-help-section__content ul li {
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 4px;
}
.iv-ui-my-accordion__header__m-item-expanded .iv-ui-my-accordion__header__text {
    font-weight: 500;
}
.iv-ui-accordion-expandable-item-content {
    border-top: 1px solid rgba(182,188,196,.3);
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    line-height: 140%;
    padding: 24px;
}
.iv-help-item-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.iv-faces-help-connection-means {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 24px;
}
.iv-faces-help-connection-means__preface {
    padding-bottom: 24px;
    width: 100%;
}
.iv-faces-help-connection-means__item {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    padding-right: 40px;
    width: 50%;
}
.iv-faces-help-connection-means__item__title {
    font-weight: 700;
    padding-bottom: 24px;
    width: 100%;
}
.iv-faces-help-connection-means__item__image {
    height: 32px;
    line-height: 32px;
    max-width: 360px;
    padding-bottom: 54px;
    text-align: center;
}
.iv-faces-help-connection-means__item__text {
    flex: 1 1 auto;
    padding-bottom: 16px;
    width: 100%;
}
.iv-ui-svg-icon-active {
    margin: auto;
    width: 200px;
    height: 100px;
    color: white;
    transform: rotate(180deg);
    animation: mymove 5s infinite;
}
.iv-ui-accordion-item-header:hover {
    background-color: rgba(182,188,196,.1);
}
.iv-ui-accordion-item-header {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    height: 50px;
    line-height: 16px;
    padding: 0 24px;
    -webkit-user-select: none;
    user-select: none;
}
.iv-ui-svg-icon-active {
    margin: auto;
    width: 200px;
    height: 100px;
    color: white;
    transform: rotate(180deg);
    animation: mymove 5s infinite;
}
</style>
